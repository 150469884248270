import { commonRequest } from "@/util";
import { ActionTree } from "vuex";
import { GCMutationType } from "./mutations";

export const enum GCActionType {
  // 发起请求
  START_REQUEST = "START_REQUEST",
  // 更新页码
  UPDATE_PAGE = "UPDATE_PAGE",
}

export const globalControlStateActions: ActionTree<
  GlobalControlState,
  RootState
> = {
  // 发起查询请求
  [GCActionType.START_REQUEST]: async ({ commit, state }) => {
    const res: any = await commonRequest.get(
      state.queryInterface,
      state.queryContent
    );
    const url = state.queryInterface;
    if (url.startsWith("/query-song-collection")) {
      commit(GCMutationType.SET_SONG_COLLECTION, res.list);
    } else if (url.startsWith("/query-song")) {
      commit(GCMutationType.SET_SONG, res.list);
    }
  },

  // 更新页码
  [GCActionType.UPDATE_PAGE]: async ({ dispatch, commit }, updatePage) => {
    commit(GCMutationType.SET_QUERY_PAGE, updatePage);
    await dispatch(GCActionType.START_REQUEST);
  },
};
