import { TabName } from "@/common/constants";
import { MutationTree } from "vuex";

export const enum GCMutationType {
  // 追加请求
  ADD_REQUEST = "ADD_REQUEST",
  // 结束请求
  END_REQUEST = "END_REQUEST",
  // 设置当前标签
  SET_ACTIVE_TAB = "SET_ACTIVE_TAB",
  // 设置歌曲集合列表
  SET_SONG_COLLECTION = "SET_SONG_COLLECTION",
  // 设置歌曲列表
  SET_SONG = "SET_SONG",
  // 设置查询接口
  SET_QUERY_INTERFACE = "SET_QUERY_INTERFACE",
  // 设置查询条件
  SET_QUERY_CONTENT = "SET_QUERY_CONTENT",
  // 设置查询页码
  SET_QUERY_PAGE = "SET_QUERY_PAGE",
  // 设置总页码
  SET_TOTAL_PAGE = "SET_TOTAL_PAGE",
  // 设置每页查询结果数目
  SET_PAGE_SIZE = "SET_PAGE_SIZE",
}

export const globalControlStateMutations: MutationTree<GlobalControlState> = {
  // 追加请求
  [GCMutationType.ADD_REQUEST](state) {
    state.requestCounter++;
  },

  // 结束请求
  [GCMutationType.END_REQUEST](state) {
    state.requestCounter--;
  },

  // 设置当前标签
  [GCMutationType.SET_ACTIVE_TAB](state, tabName) {
    state.activeTab = tabName;
  },

  // 设置歌曲集合列表
  [GCMutationType.SET_SONG_COLLECTION](state, data) {
    state.songCollectionResourceList = data;
  },

  // 设置歌曲列表
  [GCMutationType.SET_SONG](state, data) {
    state.songResourceList = data;
  },

  // 设置查询接口
  [GCMutationType.SET_QUERY_INTERFACE](state, url) {
    state.queryInterface = url;
    if (url.startsWith("/query-song")) {
      state.activeTab = TabName.song;
    } else if (url.startsWith("/query-song-collection")) {
      state.activeTab = TabName.songCollection;
    }
  },

  // 设置查询条件
  [GCMutationType.SET_QUERY_CONTENT](state, params) {
    state.queryContent = params;
  },

  // 设置查询页码
  [GCMutationType.SET_QUERY_PAGE](state, curPage) {
    state.queryContent.queryPage = curPage;
  },

  // 设置总页码
  [GCMutationType.SET_TOTAL_PAGE](state, totalPage) {
    state.totalPage = totalPage;
  },

  // 设置每页查询结果数目
  [GCMutationType.SET_PAGE_SIZE](state, pageSize) {
    state.pageSize = pageSize;
  },
};
