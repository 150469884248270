
import NavBar from "@/components/NavBar.vue";
import Loading from "@/components/Loading.vue";
import { defineComponent } from "@vue/runtime-core";
import { reactive, toRefs } from "vue";
import { mapGetters } from "vuex";
import { ModuleName } from "./store";
import { GCGetterType } from "./store/global-control/getters";

export default defineComponent({
  name: "App",

  components: {
    // NavBar,
    Loading,
  },

  setup() {
    const data = reactive({});

    return {
      ...toRefs(data),
    };
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(ModuleName.GC, {
      isInteracting: GCGetterType.isInteracting,
    }),
  },
});
