import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "description-container" }
const _hoisted_3 = { class: "description-content" }
const _hoisted_4 = { class: "description-return" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_collapse_item = _resolveComponent("van-collapse-item")!
  const _component_van_collapse = _resolveComponent("van-collapse")!
  const _component_van_button = _resolveComponent("van-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_van_collapse, {
          modelValue: _ctx.activeName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeName) = $event)),
          accordion: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_van_collapse_item, { name: "1" }, {
              title: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("div", { class: "question" }, "Q：这是个什么网站？", -1)
              ])),
              default: _withCtx(() => [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "answer" }, "A：全自动小菜播放器", -1))
              ]),
              _: 1
            }),
            _createVNode(_component_van_collapse_item, { name: "2" }, {
              title: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("div", { class: "question" }, "Q：这个网站都有些什么功能？", -1)
              ])),
              default: _withCtx(() => [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "answer" }, " A：听歌。。。目前还只能听歌。。。而且是随机播放的 ", -1)),
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "answer" }, "有的时候保持些未知感不也挺好的吗？", -1))
              ]),
              _: 1
            }),
            _createVNode(_component_van_collapse_item, { name: "3" }, {
              title: _withCtx(() => _cache[7] || (_cache[7] = [
                _createElementVNode("div", { class: "question" }, "Q：功能是不是有点少？", -1)
              ])),
              default: _withCtx(() => [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "answer" }, [
                  _createTextVNode(" A：这怎么能叫少呢？这。。这叫专一.jpg"),
                  _createElementVNode("br")
                ], -1)),
                _cache[9] || (_cache[9] = _createElementVNode("div", { class: "answer" }, " （PS：其他功能还在陆续开发中，如查询、过滤、统计等，更新周期大概1~2月 ", -1))
              ]),
              _: 1
            }),
            _createVNode(_component_van_collapse_item, { name: "4" }, {
              title: _withCtx(() => _cache[10] || (_cache[10] = [
                _createElementVNode("div", { class: "question" }, "Q：我该怎么反馈问题？", -1)
              ])),
              default: _withCtx(() => [
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "answer" }, [
                  _createTextVNode(" A：参考 "),
                  _createElementVNode("a", {
                    href: "https://t.bilibili.com/649648013174636565",
                    target: "_blank"
                  }, " 这条动态 ")
                ], -1))
              ]),
              _: 1
            }),
            _createVNode(_component_van_collapse_item, { name: "90" }, {
              title: _withCtx(() => _cache[12] || (_cache[12] = [
                _createElementVNode("div", null, "统计信息", -1)
              ])),
              default: _withCtx(() => [
                _cache[13] || (_cache[13] = _createElementVNode("p", null, " 本站已收录全平台（包括油管会限）歌曲（4600+ / ???）首，600余类单曲，收录日期范围： ", -1)),
                _cache[14] || (_cache[14] = _createElementVNode("ul", null, [
                  _createElementVNode("li", null, "2020.08（156/158）"),
                  _createElementVNode("li", null, "2020.09（157/166）"),
                  _createElementVNode("li", null, "2020.10（275/275）"),
                  _createElementVNode("li", null, "2020.11（235/240）"),
                  _createElementVNode("li", null, "2020.12（305/311）"),
                  _createElementVNode("li", null, "2021.01（351/351）"),
                  _createElementVNode("li", null, "2021.02（293/294）"),
                  _createElementVNode("li", null, "2021.03（298/298）"),
                  _createElementVNode("li", null, "2021.04（325/325）"),
                  _createElementVNode("li", null, "2021.05（340/340）"),
                  _createElementVNode("li", null, "2021.06（61/188）"),
                  _createElementVNode("li", null, "2021.07（60/???）"),
                  _createElementVNode("li", null, "2021.12（128/128）"),
                  _createElementVNode("li", null, "2022.01（170/170）"),
                  _createElementVNode("li", null, "2022.02（159/159）"),
                  _createElementVNode("li", null, "2022.03（174/174）"),
                  _createElementVNode("li", null, "2022.04（144/144）"),
                  _createElementVNode("li", null, "2022.05（196/196）"),
                  _createElementVNode("li", null, "2022.06（119/119）"),
                  _createElementVNode("li", null, "2022.07（179/179）"),
                  _createElementVNode("li", null, "2022.08（119/119）"),
                  _createElementVNode("li", null, "2022.09（113/113）"),
                  _createElementVNode("li", null, "2022.10（95/95）"),
                  _createElementVNode("li", null, "2022.11（161/161）"),
                  _createElementVNode("li", null, "2022.12（待更新/168）"),
                  _createElementVNode("li", null, "2023.01（待更新/147）"),
                  _createElementVNode("li", null, "2023.02（0/0）"),
                  _createElementVNode("li", null, "2023.03（88/88）"),
                  _createElementVNode("li", null, "2023.04（105/113）"),
                  _createElementVNode("li", null, "2023.05（待更新/121）")
                ], -1))
              ]),
              _: 1
            }),
            _createVNode(_component_van_collapse_item, { name: "99" }, {
              title: _withCtx(() => _cache[15] || (_cache[15] = [
                _createElementVNode("div", null, "特别感谢", -1)
              ])),
              default: _withCtx(() => [
                _cache[16] || (_cache[16] = _createElementVNode("p", null, "在此感谢对本站提供了大量歌曲资源的组织及个人：", -1)),
                _cache[17] || (_cache[17] = _createElementVNode("ul", null, [
                  _createElementVNode("li", null, "圈"),
                  _createElementVNode("li", null, "椎名ta大姨妈"),
                  _createElementVNode("li", null, [
                    _createElementVNode("a", {
                      href: "https://shiinanoha.com/",
                      target: "_blank"
                    }, " 菜の花字幕组 ")
                  ]),
                  _createElementVNode("li", null, "Saaawyeeer"),
                  _createElementVNode("li", null, [
                    _createElementVNode("a", {
                      href: "https://music.163.com/#/djradio?id=963107361",
                      target: "_blank"
                    }, " 牵丝蝠的古堡 ")
                  ])
                ], -1))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_van_button, {
          class: "description-return-button",
          type: "primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickButton()))
        }, {
          default: _withCtx(() => _cache[18] || (_cache[18] = [
            _createTextVNode(" 关闭 ")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}