import { TabName } from "@/common/constants";
import { Module } from "vuex";
import { globalControlStateActions } from "./actions";
import { globalControlStageGetters } from "./getters";
import { globalControlStateMutations } from "./mutations";

/**
 * 初期化全局控制状态
 */
const initGlobalControlState: GlobalControlState = {
  requestCounter: 0,
  activeTab: TabName.songCollection,
  queryInterface: "",
  queryContent: {},
  songCollectionResourceList: [],
  songResourceList: [],
  totalPage: 0,
  pageSize: 20,
};

export const gc: Module<GlobalControlState, RootState> = {
  namespaced: true,

  state: initGlobalControlState,

  getters: globalControlStageGetters,

  actions: globalControlStateActions,

  mutations: globalControlStateMutations,
};
