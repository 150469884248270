import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "footer-container" }
const _hoisted_2 = { class: "text-container" }
const _hoisted_3 = { class: "song-collection-part" }
const _hoisted_4 = { class: "singer" }
const _hoisted_5 = { class: "song-part" }
const _hoisted_6 = {
  key: 0,
  class: "song-detail-info-content"
}
const _hoisted_7 = {
  key: 0,
  class: "tag"
}
const _hoisted_8 = {
  key: 1,
  class: "creator"
}
const _hoisted_9 = {
  key: 2,
  class: "creator"
}
const _hoisted_10 = { class: "progress-bar" }
const _hoisted_11 = { class: "slider" }
const _hoisted_12 = { class: "media-controls" }
const _hoisted_13 = { class: "volume-control" }
const _hoisted_14 = { class: "volume-button" }
const _hoisted_15 = { class: "volume-bar" }
const _hoisted_16 = { class: "playing-control" }
const _hoisted_17 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_tag = _resolveComponent("van-tag")!
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_slider = _resolveComponent("van-slider")!
  const _component_play_list = _resolveComponent("play-list")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("footer", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: "song-title",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickSongTitle(_ctx.songTitle)))
            }, _toDisplayString(_ctx.songTitle), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.coverSingerNames || "未知歌手"), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "performance-date",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickPerformanceDate(_ctx.performanceDate)))
            }, _toDisplayString(_ctx.performanceDate), 1),
            (_ctx.showDetail)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                  (_ctx.tags.length === 0)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _cache[12] || (_cache[12] = [
                        _createElementVNode("span", null, "待补充标签", -1)
                      ])))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag, index) => {
                    return (_openBlock(), _createBlock(_component_van_tag, {
                      class: "tag",
                      type: "primary",
                      size: "small",
                      round: "",
                      color: "#1C1C1C",
                      key: index
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(tag), 1)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  (_ctx.creator)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, "|| " + _toDisplayString(_ctx.creator), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_9, "待补充来源"))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("span", {
              class: "song-detail-info-control",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDetail = !_ctx.showDetail))
            }, [
              _createVNode(_component_van_icon, {
                name: _ctx.showDetail ? 'arrow-left' : 'arrow-down'
              }, null, 8, ["name"]),
              _cache[13] || (_cache[13] = _createElementVNode("span", null, "详细", -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("span", null, _toDisplayString(_ctx.getTime(_ctx.currentTime)), 1),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_van_slider, {
              modelValue: _ctx.position,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.position) = $event)),
              min: 0,
              max: 1,
              step: 0.001,
              onChange: _ctx.updatePosition
            }, null, 8, ["modelValue", "onChange"])
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.getTime(_ctx.duration)), 1)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_van_icon, {
                name: _ctx.getVolumeButton(),
                size: "25"
              }, null, 8, ["name"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_van_slider, {
                modelValue: _ctx.volume,
                "onUpdate:modelValue": [
                  _cache[4] || (_cache[4] = ($event: any) => ((_ctx.volume) = $event)),
                  _cache[5] || (_cache[5] = ($event: any) => (_ctx.onChangeVolume(_ctx.volume)))
                ]
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_van_icon, {
              class: _normalizeClass(_ctx.isFirstSong ? 'unable' : ''),
              name: require('@/assets/image/play-prev.svg'),
              size: "25",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.playPrevSong()))
            }, null, 8, ["class", "name"]),
            _createVNode(_component_van_icon, {
              name: _ctx.isPlaying ? 'pause' : 'play',
              size: "25",
              color: "white",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.togglePlaying()))
            }, null, 8, ["name"]),
            _createVNode(_component_van_icon, {
              name: require('@/assets/image/play-next.svg'),
              size: "25",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.playNextSong()))
            }, null, 8, ["name"])
          ])
        ]),
        _createVNode(_component_van_popup, {
          show: _ctx.showPlayList,
          position: "right",
          closeable: "",
          onClickCloseIcon: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showPlayList = false))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_play_list)
          ]),
          _: 1
        }, 8, ["show"])
      ])
    ]),
    _createElementVNode("audio", {
      ref: "audioRef",
      preload: "",
      type: "audio/mpeg",
      src: _ctx.src,
      onEnded: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.audioEnd && _ctx.audioEnd(...args))),
      onTimeupdate: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.updateTime && _ctx.updateTime(...args)))
    }, null, 40, _hoisted_17)
  ], 64))
}