
import { ModuleName } from "@/store";
import { CSGetterType } from "@/store/current-song/getters";
import { PLGetterType } from "@/store/play-list/getters";
import { logger } from "@/util";
import { defineComponent, reactive, toRefs } from "vue";
import { mapGetters, useStore } from "vuex";

/**
 * 播放列表
 */
export default defineComponent({
  name: "PlayList",

  setup() {
    logger.info("play list component setup");
    const store = useStore();
    const data = reactive({});

    return {
      store,
      ...toRefs(data),
    };
  },

  computed: {
    // 当前播放音频
    ...mapGetters(ModuleName.CS, {
      isPlaying: CSGetterType.isPlaying,
      isLoading: CSGetterType.isLoading,
      duration: CSGetterType.duration,
      currentTime: CSGetterType.currentTime,
      songName: CSGetterType.songName,
      performanceDate: CSGetterType.performanceDate,
      src: CSGetterType.src,
    }),
    // 播放列表
    ...mapGetters(ModuleName.PL, {
      songs: PLGetterType.playList,
      playIndex: PLGetterType.playIndex,
    }),
  },

  data() {
    return {};
  },
});
