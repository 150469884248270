import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_row = _resolveComponent("van-row")!
  const _component_van_col = _resolveComponent("van-col")!

  return (_openBlock(), _createBlock(_component_van_row, { class: "play-list-container" }, {
    default: _withCtx(() => [
      _createVNode(_component_van_row, { class: "play-list-title" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" 当前播放 ")
        ])),
        _: 1
      }),
      _createVNode(_component_van_row, { class: "play-list-tabbar" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" tabbar ")
        ])),
        _: 1
      }),
      _createVNode(_component_van_row, { class: "play-list-main" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.songs, (song, index) => {
            return (_openBlock(), _createBlock(_component_van_row, {
              class: "play-list-item",
              key: index
            }, {
              default: _withCtx(() => [
                _createVNode(_component_van_col, { class: "paly-list-item_song-name" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(song.songName), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_van_col, { class: "paly-list-item_singers" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_van_col, { class: "paly-list-item_performance" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(song.performance), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}