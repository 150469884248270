import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "root" }
const _hoisted_2 = { class: "search-bar-container" }
const _hoisted_3 = { class: "current-song-list-container" }
const _hoisted_4 = { class: "song-list-container" }
const _hoisted_5 = { class: "song-list-cover" }
const _hoisted_6 = { class: "song-list-container" }
const _hoisted_7 = { class: "song-list-cover" }
const _hoisted_8 = { class: "song-list-container" }
const _hoisted_9 = { class: "song-list-cover" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_search = _resolveComponent("van-search")!
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_tag = _resolveComponent("van-tag")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_list = _resolveComponent("van-list")!
  const _component_van_button = _resolveComponent("van-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_van_search, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        placeholder: "请输入关键词"
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_van_image, {
        width: "90%",
        height: "100%",
        fit: "cover",
        src: "https://img.yzcdn.cn/vant/cat.jpeg"
      }),
      _createVNode(_component_van_list, { class: "play-list-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_van_cell, {
            class: "list-item-container",
            icon: "location-o",
            "title-class": "song-detail-container"
          }, {
            title: _withCtx(() => [
              _cache[3] || (_cache[3] = _createElementVNode("span", null, "歌名", -1)),
              _createVNode(_component_van_tag, {
                round: "",
                type: "danger"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("标签1")
                ])),
                _: 1
              }),
              _createVNode(_component_van_tag, {
                round: "",
                type: "success"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("原唱")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_van_cell, {
            class: "list-item-container",
            icon: "location-o",
            "title-class": "song-detail-container"
          }, {
            title: _withCtx(() => [
              _cache[7] || (_cache[7] = _createElementVNode("span", null, "歌名", -1)),
              _createVNode(_component_van_tag, {
                round: "",
                type: "danger"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("标签1")
                ])),
                _: 1
              }),
              _createVNode(_component_van_tag, {
                round: "",
                type: "success"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("原唱")
                ])),
                _: 1
              }),
              _createVNode(_component_van_tag, {
                round: "",
                type: "warning"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("完成度")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_van_button, {
        icon: "",
        type: "primary"
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode("换一批")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_van_image, {
          width: "100%",
          height: "100%",
          fit: "cover",
          src: "https://img.yzcdn.cn/vant/cat.jpeg"
        })
      ]),
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "song-list-description" }, "歌单描述", -1))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_van_image, {
          width: "100%",
          height: "100%",
          fit: "cover",
          src: "https://img.yzcdn.cn/vant/cat.jpeg"
        })
      ]),
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "song-list-description" }, "歌单描述", -1))
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_van_image, {
          width: "100%",
          height: "100%",
          fit: "cover",
          src: "https://img.yzcdn.cn/vant/cat.jpeg"
        })
      ]),
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "song-list-description" }, "歌单描述", -1))
    ])
  ]))
}