import _ from "lodash";

type MessageType = any;

const enum Level {
  INFO,
  WARN,
  ERROR,
}

const outputIfUnNil = (msg: MessageType, level: Level): void => {
  if (_.isNil(msg)) {
    return;
  }

  switch (level) {
    case Level.INFO:
      console.info(msg);
      break;
    case Level.WARN:
      console.warn(msg);
      break;
    case Level.ERROR:
      console.error(msg);
      break;
    default:
      console.info(msg);
  }
};

export const logger = {
  info: (hint: string, obj?: MessageType): void => {
    logger.log(hint, obj, Level.INFO);
  },

  warn: (hint: string, obj?: MessageType): void => {
    logger.log(hint, obj, Level.WARN);
  },

  error: (hint: string, obj?: MessageType): void => {
    logger.log(hint, obj, Level.ERROR);
  },

  log: (hint: string, obj?: MessageType, level: Level = Level.INFO): void => {
    outputIfUnNil(hint, level);
    outputIfUnNil(obj, level);
  },
};
