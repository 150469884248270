import { createStore } from "vuex";
import { cs } from "./current-song";
import { gc } from "./global-control";
import { hr } from "./history-record";
import { pl } from "./play-list";

export const store = createStore<RootState>({
  state: {
    version: "0.0.0",
  },

  modules: {
    cs, // 当前播放歌曲模块
    gc, // 全局控制状态模块
    hr, // 历史记录模块
    pl, // 播放列表模块
  },
});

export const enum ModuleName {
  CS = "cs",
  GC = "gc",
  HR = "hr",
  PL = "pl",
}
