import { Url } from "@/common/constants";
import { ModuleName, store } from "@/store";
import { GCGetterType } from "@/store/global-control/getters";
import { GCMutationType } from "@/store/global-control/mutations";
import { commonRequest, logger } from "@/util";
import _ from "lodash";
import { Toast } from "vant";

/** 取得初始化数据 */
export const queryAll = async (): Promise<QueryAllResponse> => {
  return await commonRequest.get<void, QueryAllResponse>(Url.v2.fetchData);
};

/**
 * 通过传入参数查询歌曲及相关信息
 *
 * @param params 查询歌曲请求
 */
export const querySong = async (
  params: QuerySongRequest
): Promise<QuerySongResponse> => {
  try {
    const res = await commonRequest.get<QuerySongRequest, QuerySongResponse>(
      Url.v1.query.specified.song,
      params
    );

    setGlobalState(Url.v1.query.specified.song, params, res);

    if (_.isEmpty(res.list)) {
      Toast("没有查询到结果");
    }
    return res;
  } catch (e: any) {
    Toast(_.toString(e.errorMessages));
  }
};

/**
 * 查询所有歌曲及相关信息
 *
 * @param params 查询歌曲请求
 */
export const queryAllSong = async (
  params: QuerySongRequest
): Promise<QuerySongResponse> => {
  const res = await commonRequest.get<QuerySongRequest, QuerySongResponse>(
    Url.v1.query.all.song,
    params
  );

  setGlobalState(Url.v1.query.all.song, params, res);

  if (_.isEmpty(res.list)) {
    Toast("没有查询到结果");
  }
  return res;
};

/**
 * 查询随机歌曲及相关信息
 *
 * @param amount 查询数量
 */
export const queryRandomSong = async (
  amount: number
): Promise<QuerySongResponse> => {
  const res = await commonRequest.get<any, QuerySongResponse>(
    Url.v1.query.random.song,
    { amount: amount }
  );

  const params =
    store.getters[_.join([ModuleName.GC, GCGetterType.queryContent], "/")];
  setGlobalState(Url.v1.query.random.song, params, res);

  if (_.isEmpty(res.list)) {
    Toast("没有查询到结果");
  }
  return res;
};

/**
 * 通过传入参数查询歌曲集合及相关信息
 *
 * @param params 查询歌曲集合请求
 */
export const querySongCollection = async (
  params: QuerySongCollectionRequest
): Promise<QuerySongCollectionResponse> => {
  const res = await commonRequest.get<
    QuerySongCollectionRequest,
    QuerySongCollectionResponse
  >(Url.v1.query.specified.songCollection, params);

  setGlobalState(Url.v1.query.specified.songCollection, params, res);

  if (_.isEmpty(res.list)) {
    Toast("没有查询到结果");
  }
  return res;
};

/**
 * 查询所有歌曲集合及相关信息
 *
 * @param params 查询歌曲集合请求
 */
export const queryAllSongCollection = async (
  params: QuerySongCollectionRequest
): Promise<QuerySongCollectionResponse> => {
  const res = await commonRequest.get<
    QuerySongCollectionRequest,
    QuerySongCollectionResponse
  >(Url.v1.query.all.songCollection, params);

  setGlobalState(Url.v1.query.all.songCollection, params, res);

  if (_.isEmpty(res.list)) {
    Toast("没有查询到结果");
  }
  return res;
};

export const queryAllTag = async (): Promise<QueryTagResponse> => {
  const res: QueryTagResponse = await commonRequest.get(Url.v1.query.all.tag);

  if (_.isEmpty(res.list)) {
    Toast("没有查询到结果");
  }

  // todo session存取操作应转移至别处
  sessionStorage.setItem("tags", JSON.stringify(res.list));
  return res;
};

const setGlobalState = (
  url: string,
  params: BaseQueryRequest,
  res: BaseQueryResponse
) => {
  store.commit(
    ModuleName.GC.concat("/").concat(GCMutationType.SET_QUERY_INTERFACE),
    url
  );

  store.commit(
    ModuleName.GC.concat("/").concat(GCMutationType.SET_QUERY_CONTENT),
    params
  );

  if (url.startsWith("/query-song-collection")) {
    store.commit(
      ModuleName.GC.concat("/").concat(GCMutationType.SET_SONG_COLLECTION),
      res.list
    );
  } else if (url.startsWith("/query-song")) {
    store.commit(
      ModuleName.GC.concat("/").concat(GCMutationType.SET_SONG),
      res.list
    );
  } else {
    logger.error("url error");
  }

  store.commit(
    ModuleName.GC.concat("/").concat(GCMutationType.SET_QUERY_PAGE),
    _.isEmpty(params.queryPage) ? 1 : params.queryPage
  );

  store.commit(
    ModuleName.GC.concat("/").concat(GCMutationType.SET_TOTAL_PAGE),
    res.totalPages
  );

  store.commit(
    ModuleName.GC.concat("/").concat(GCMutationType.SET_PAGE_SIZE),
    res.pageSize
  );
};
