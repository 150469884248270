import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_col = _resolveComponent("van-col")!
  const _component_van_tag = _resolveComponent("van-tag")!
  const _component_van_row = _resolveComponent("van-row")!
  const _component_van_pagination = _resolveComponent("van-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.songCollectionResourceList, (songCollectionResource, index) => {
      return (_openBlock(), _createBlock(_component_van_row, {
        class: "song-colleciton-item",
        key: index
      }, {
        default: _withCtx(() => [
          _createVNode(_component_van_col, { span: "2" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString((_ctx.currentPage - 1) * _ctx.pageSize + index + 1), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_van_col, {
            span: "5",
            style: {"text-align":"left"},
            onClick: ($event: any) => (
        _ctx.onClickSongCollection(
          songCollectionResource.songCollection.songCollectionId
        )
      )
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(songCollectionResource.songCollection.songTitle), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_van_col, {
            class: "song-collection-tag-list",
            span: "17",
            style: {"text-align":"left"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(songCollectionResource.singers, (singer, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "tag-item",
                  key: index
                }, [
                  _createVNode(_component_van_tag, {
                    type: "primary",
                    size: "large",
                    plain: ""
                  }, {
                    default: _withCtx(() => [
                      _cache[1] || (_cache[1] = _createElementVNode("span", { title: "singer.singerName" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(singer.singerName), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024))
    }), 128)),
    (_ctx.songCollectionResourceList)
      ? (_openBlock(), _createBlock(_component_van_pagination, {
          key: 0,
          modelValue: _ctx.currentPage,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
          "page-count": _ctx.totalPage,
          "show-page-size": 20,
          "force-ellipses": "",
          onChange: _ctx.changePage
        }, null, 8, ["modelValue", "page-count", "onChange"]))
      : _createCommentVNode("", true)
  ], 64))
}