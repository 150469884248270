import { GetterTree } from "vuex";

export const enum PLGetterType {
  // 播放列表
  playList = "playList",
  // 定位用播放序号
  playIndex = "playIndex",
  // 播放方式
  playMode = "playMode",
  // 是否为播放列表当中最后一首
  isLastSong = "isLastSong",
  // 是否为播放列表当中第一首
  isFirstSong = "isFirstSong",
}

export const playListStageGetters: GetterTree<PlayListState, RootState> = {
  [PLGetterType.playList]: (state) => state.playList,

  [PLGetterType.playIndex]: (state) => state.playIndex,

  [PLGetterType.playMode]: (state) => state.playMode,

  [PLGetterType.isLastSong]: (state) =>
    state.playList.length > 0 && state.playIndex === state.playList.length - 1,

  [PLGetterType.isFirstSong]: (state) =>
    state.playList.length > 0 && state.playIndex === 0,
};
