
import {
  addSinger,
  addSongCollection,
  addTag,
  importSong,
  relateOriginalRelation,
} from "@/api/command.api";
import { queryAllTag } from "@/api/query.api";
import {
  ImportSongRequest,
  QueryTagResponse,
  Singer,
  Song,
  SongCollection,
  Tag,
} from "@/types";
import { logger } from "@/util";
import { defineComponent } from "@vue/runtime-core";
import _ from "lodash";
import type { TreeSelectChild, TreeSelectItem } from "vant";
import { Toast } from "vant";
import { reactive, toRefs } from "vue";

/**
 * 操作用组件
 */
export default defineComponent({
  // 组件名
  name: "Operation",

  // 初始化组件
  setup() {
    logger.info("operate component setup");
    const data = reactive({});

    return {
      ...toRefs(data),
    };
  },

  data() {
    // 歌手
    const singer: Singer = {
      singerId: 0,
      singerName: "",
    };
    // 单曲
    const song: Song = {
      songId: 0,
      songName: "",
      path: "",
      performanceDate: "",
      playOrder: 1,
      membersOnly: false,
      uploader: "ADDD",
      creator: "",
    };
    // 歌曲集合
    const songCollection: SongCollection = {
      songCollectionId: 0,
      songTitle: "",
      chineseTitle: "",
      englishTitle: "",
    };
    // 标签
    const tag: Tag = {
      id: 0,
      type: "",
      value: "",
    };
    // 树状选项项目
    const tags: TreeSelectItem[] = [];
    // 导入歌曲列表
    const importSongList: Array<any> = [];
    // 当前单曲
    const curSong = {
      songId: 0,
      songName: "",
      path: "",
      performanceDate: "",
      tagIds: {},
    };
    return {
      activeTabIndex: 0,
      song,
      singer,
      songCollection,
      tag,
      // 歌曲文件内容
      file: {
        content: "",
        name: "",
        type: "",
        size: "",
      },
      // 设置日历组件是否跳出
      showCalender: false,
      importSongList,
      showTagList: false,
      activeTagIndex: 0,
      tags,
      selectSongId: 0,
      selectIds: [],
      curSong,
      // 默认标签
      defaultTags: [],
    };
  },

  methods: {
    /**
     * 点击新增歌手按钮
     */
    async onClickAddSinger() {
      // 新增歌手
      await addSinger({ singerName: this.singer.singerName });
    },

    /**
     * 点击新增歌曲集合按钮
     */
    async onClickAddCollection() {
      // 新增歌曲集合
      await addSongCollection({
        songTitle: this.songCollection.songTitle,
        chineseTitle: this.songCollection.chineseTitle,
        englishTitle: this.songCollection.englishTitle,
      });
    },

    /**
     * 点击设定原唱按钮
     */
    async onClickRelateOriginalRelation() {
      // 绑定歌曲集合与歌手
      await relateOriginalRelation({
        songTitle: this.songCollection.songTitle,
        singerName: this.singer.singerName,
      });
    },

    /**
     * 确认弹唱日期
     */
    onConfirmPerformanceDate(value: any): void {
      this.showCalender = false;
      this.song.performanceDate = value.toLocaleDateString();
    },

    /**
     * 点击导入按钮
     */
    async onClickImportSong() {
      const data: ImportSongRequest = {
        audios: this.importSongList.reverse(),
      };
      // 上传单曲
      await importSong(data);
    },

    /**
     * 新增导入歌曲
     */
    async onClickUploadSong() {
      const tags: Tag[] = JSON.parse(sessionStorage.getItem("tags"));
      if (_.isEmpty(tags)) {
        await queryAllTag();
      }
      const song = {
        fileName: this.file.name,
        performanceDate: this.song.performanceDate,
        songFileContent: this.file.content,
        songTitle: this.songCollection.songTitle,
        tagIds: this.defaultTags,
        // 歌曲大小
        size: this.file.size,
        playOrder: this.song.playOrder++,
        membersOnly: this.song.membersOnly,
        uploader: this.song.uploader,
        creator: this.song.creator,
      };
      // todo 确认导入
      this.importSongList.unshift(song);
    },

    /**
     * 绑定标签
     */
    async onClickBindTagRelationButton(index: number) {
      // todo 弹窗出现tag选择栏
      this.showTagList = true;
      const response: QueryTagResponse = await queryAllTag();

      this.selectIds = [];
      this.activeTagIndex = 0;
      this.curSong = this.importSongList[index];

      this.tags = [];
      _.uniq(
        response.list.map((tag) => {
          return tag.type;
        })
      ).forEach((type) => {
        const item: TreeSelectItem = {
          text: type,
          children: [],
        };
        response.list
          .filter((tag) => {
            return _.isEqual(tag.type, type);
          })
          .forEach((tag) => {
            const child: TreeSelectChild = {
              id: tag.id,
              text: tag.value,
            };
            item.children.push(child);
          });
        this.tags.push(item);
      });
    },

    /**
     * 设置默认标签
     */
    async setDefaultTags() {
      this.defaultTags = this.selectIds;
      this.chooseTags();
    },

    /**
     * 选择标签
     */
    async chooseTags() {
      this.showTagList = false;
      this.curSong.tagIds = this.selectIds;
    },

    /**
     * 根据标签id获取标签值
     */
    getTagValue(tagIds: number[]): string[] {
      const tags: Tag[] = JSON.parse(sessionStorage.getItem("tags"));

      return tags
        .filter((tag) => {
          return tagIds.includes(tag.id);
        })
        .map((tag) => {
          return tag.value;
        });
    },

    /**
     * 点击新增标签按钮
     */
    async onClickAddTag() {
      // 新增标签
      await addTag({
        tagType: this.tag.type,
        tagValue: this.tag.value,
      });
    },

    /**
     * 读取预操作
     */
    beforeRead(file: any): boolean {
      // 检查文件类型
      if (file.type !== "audio/wav" && file.type !== "audio/mpeg") {
        Toast("请上传标准音乐格式文件");
        return false;
      }
      return true;
    },

    /**
     * 读取后操作
     */
    afterRead(event: any): void {
      // 分析并获取文件相关信息
      this.file.content = event.content;
      this.file.name = event.file.name;
      this.file.type = event.file.type;
      // todo 文件大小设定+kb
      this.file.size = (event.file.size / 1024 / 1024).toFixed(2) + "MB";
    },
  },
});
