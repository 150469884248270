
import {
  queryAllSong,
  queryAllSongCollection,
  querySong,
  querySongCollection,
} from "@/api/query.api";
import { TabName } from "@/common/constants";
import { QueryOption } from "@/common/enums";
import SongComp from "@/components/Song.vue";
import SongCollectionComp from "@/components/SongCollection.vue";
import { ModuleName, store } from "@/store";
import { GCGetterType } from "@/store/global-control/getters";
import { GCMutationType } from "@/store/global-control/mutations";
import type { Singer, Song, SongCollection, Tag } from "@/types";
import { logger } from "@/util";
import { defineComponent } from "@vue/runtime-core";
import _ from "lodash";
import { reactive, toRefs } from "vue";
import { mapMutations } from "vuex";

/**
 * 管理用组件
 */
export default defineComponent({
  // 组件名
  name: "Manage",

  // 使用到的组件
  components: {
    SongComp,
    SongCollectionComp,
  },

  // 初始化组件
  setup() {
    logger.info("manage component setup");
    const data = reactive({});

    return {
      ...toRefs(data),
    };
  },

  data() {
    // 歌手
    const singer: Singer = {
      singerId: 0,
      singerName: "",
    };
    // 单曲
    const song: Song = {
      songId: 0,
      songName: "",
      path: "",
      performanceDate: "",
    };
    // 歌曲集合
    const songCollection: SongCollection = {
      songCollectionId: 0,
      songTitle: "",
      chineseTitle: "",
      englishTitle: "",
    };
    // 标签
    const tag: Tag = {
      id: 0,
      type: "",
      value: "",
    };

    return {
      // 查询内容
      queryContent: "",
      // 当前查询类型
      currentQueryType: QueryOption.UNSPECIFIED,
      // 查询选项
      queryOptions: [
        { text: "歌曲标题", value: QueryOption.SONG_TITLE },
        { text: "歌手", value: QueryOption.SINGER_NAME },
        { text: "弹唱日期", value: QueryOption.PERFORMANCE_DATE },
        { text: "标签id", value: QueryOption.TAG_ID },
      ],
      song,
      singer,
      songCollection,
      tag,
      // 歌曲文件内容
      file: {
        content: "",
        name: "",
        type: "",
        size: "",
      },
      // 设置日历组件是否跳出
      showCalender: false,
      // 展示歌曲列表
      showSongList: [],
    };
  },

  computed: {
    // tab标签名
    activeName: {
      get: () => {
        return store.getters[
          _.join([ModuleName.GC, GCGetterType.tabName], "/")
        ];
      },

      set: (value: any) => {
        store.commit(
          _.join([ModuleName.GC, GCMutationType.SET_ACTIVE_TAB], "/"),
          value
        );
      },
    },
  },

  methods: {
    ...mapMutations(ModuleName.GC, {
      setCurrentPage: GCMutationType.SET_QUERY_PAGE,
    }),

    /**
     * 切换标签页
     */
    changeTab(tabName: string) {
      this.activeName = tabName;
    },

    /**
     * 点击查询按钮查询歌曲集合
     */
    async onClickQueryButton() {
      this.setCurrentPage(1);
      switch (this.currentQueryType) {
        // 通过歌曲标题查询
        case QueryOption.SONG_TITLE:
          await querySongCollection({
            songTitle: this.queryContent,
          });
          this.changeTab(TabName.songCollection);
          break;
        // 通过歌手查询
        case QueryOption.SINGER_NAME:
          await querySongCollection({
            singerName: this.queryContent,
          });
          this.changeTab(TabName.songCollection);
          break;
        // 通过弹唱日期查询
        case QueryOption.PERFORMANCE_DATE:
          this.changeTab(TabName.song);
          await querySong({
            performanceDate: this.queryContent,
          });
          break;
        // 通过标签id查询
        case QueryOption.TAG_ID:
          this.changeTab(TabName.song);
          await querySong({
            tagIdList: this.queryContent.split(" ").map(Number),
          });
          break;
        default:
      }
    },

    /**
     * 点击查询所有歌曲集合
     */
    async onClickQueryAllSongCollection() {
      await queryAllSongCollection({
        queryPage: 1,
      });
      this.changeTab(TabName.songCollection);
      this.currentQueryType = QueryOption.UNSPECIFIED;
    },

    /**
     * 点击查询所有歌曲
     */
    async onClickQueryAllSong() {
      await queryAllSong({ queryPage: 1 });
      this.changeTab(TabName.song);
      this.currentQueryType = QueryOption.UNSPECIFIED;
    },

    /**
     * 通过弹唱日期查询歌曲
     */
    async querySongByPerformance(performanceDate: string) {
      this.currentQueryType = QueryOption.PERFORMANCE_DATE;
      this.queryContent = performanceDate;
      await querySong({
        performanceDate: performanceDate,
      });
    },
  },
});
