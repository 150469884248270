import _ from "lodash";

export const timeUtil = {
  /**
   * 解析时间
   *
   * @param time 秒数
   * @returns mm:ss 格式时间
   */
  parseTime: (time: number): string => {
    const minute = _.floor(_.divide(time, 60));
    const second = _.floor(_.subtract(time, _.multiply(minute, 60)));

    return _.join(
      [minute, ":", _.padStart(_.toString(second), 2, "0")],
      _.stubString()
    );
  },
};
