const enum SessionName {
  expireTime = "expire_time",
  initData = "init_data",
  volume = "volume",
}

// 7d * 24h * 3600s * 1000ms
const defaultExpireDuration: number = 7 * 24 * 3600 * 1000;

const defaultVolume = 100;

export const sessionUtil = {
  getExpireTime: (): Date => {
    return JSON.parse(sessionStorage.getItem(SessionName.expireTime));
  },

  setExpireTime: (expireDuration: number = defaultExpireDuration): void => {
    sessionStorage.setItem(
      SessionName.expireTime,
      JSON.stringify(new Date().valueOf() + expireDuration)
    );
  },

  getInitData: (): QueryAllResponse => {
    return JSON.parse(sessionStorage.getItem(SessionName.initData));
  },

  setInitData: (initData: QueryAllResponse): void => {
    sessionStorage.setItem(SessionName.initData, JSON.stringify(initData));
  },

  getVolume: (): number => {
    return JSON.parse(sessionStorage.getItem(SessionName.volume));
  },

  setVolume: (volume: number = defaultVolume): void => {
    sessionStorage.setItem(SessionName.volume, JSON.stringify(volume));
  },
};
