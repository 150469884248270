
import {
  bindCoverRelation,
  bindTagRelation,
  deleteSong,
  unbindTagRelation,
} from "@/api/command.api";
import { queryAllTag, querySong } from "@/api/query.api";
import SongDetail from "@/components/SongDetail.vue";
import { ModuleName, store } from "@/store";
import { CSMutationType } from "@/store/current-song/mutations";
import { GCActionType } from "@/store/global-control/actions";
import { GCGetterType } from "@/store/global-control/getters";
import { GCMutationType } from "@/store/global-control/mutations";
import { PLGetterType } from "@/store/play-list/getters";
import { PLMutationType } from "@/store/play-list/mutations";
import {
  QuerySongResponseResource,
  QueryTagResponse,
  Song,
  Tag,
} from "@/types";
import { logger } from "@/util";
import { defineComponent } from "@vue/runtime-core";
import _ from "lodash";
import { Toast, TreeSelectChild, TreeSelectItem } from "vant";
import { reactive, toRefs } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

/**
 * 歌曲展示用组件
 */
export default defineComponent({
  components: { SongDetail },
  name: "SongComp",

  setup() {
    logger.info("song component setup");
    const data = reactive({});

    return {
      ...toRefs(data),
    };
  },

  data() {
    // 树状选项项目
    const tags: TreeSelectItem[] = [];

    return {
      selectIds: [],
      activeTagIndex: 0,
      showTagList: false,
      tags,
      selectSongId: 0,
      // 显示标签菜单
      tagMenuVisible: false,
      // 显示歌曲详细信息
      songDetailVisible: false,
      // 解绑标签歌曲id
      unbindSongId: -1,
      // 解绑标签id
      unbindTagId: -1,
      // 绑定歌手id
      bindSingerId: -1,
      // 查询用标签id
      queryTagId: -1,
      // 更新用歌曲
      updateSong: {},
    };
  },

  computed: {
    ...mapGetters(ModuleName.PL, {
      // 播放列表
      songList: PLGetterType.playList,
    }),

    ...mapGetters(ModuleName.GC, {
      // 总页码
      totalPage: GCGetterType.totalPage,
      // 每页查询结果数目
      pageSize: GCGetterType.pageSize,
      // 展示数据
      songResourceList: GCGetterType.songResourceList,
    }),

    // 当前页码
    currentPage: {
      get: () => {
        return store.getters[_.join([ModuleName.GC, GCGetterType.page], "/")];
      },

      set: (value: any) => {
        store.commit(
          _.join([ModuleName.GC, GCMutationType.SET_QUERY_PAGE], "/"),
          value
        );
      },
    },
  },

  methods: {
    ...mapMutations(ModuleName.CS, {
      updateDuration: CSMutationType.UPDATE_DURATION,
      setSong: CSMutationType.UPDATE_SONG,
      play: CSMutationType.PLAY,
      pause: CSMutationType.PAUSE,
    }),

    ...mapMutations(ModuleName.PL, {
      addSong: PLMutationType.ADD_SONG,
      clear: PLMutationType.CLEAR,
    }),

    ...mapActions(ModuleName.GC, {
      updatePage: GCActionType.UPDATE_PAGE,
    }),

    /**
     * 删除歌曲
     */
    async onClickDeleteSong(songId: number) {
      await deleteSong({
        songId: songId,
      });
    },

    /**
     * 点击播放按钮
     */
    onClickPlayButton(song: Song) {
      // todo 更新歌曲信息到缓存
      const audio = document.getElementById("audio") as HTMLAudioElement;

      audio.setAttribute("src", `${process.env.VUE_APP_SONG_SRC}${song.path}`);
      audio.oncanplaythrough = () => {
        this.updateDuration(audio.duration);
      };

      // 将歌曲添加到播放列表
      this.addSong(song);
      // 设置当前播放歌曲
      this.setSong(song);
      // 暂停播放歌曲
      this.pause();
      // 自动播放歌曲
      setTimeout(() => {
        audio.play();
        this.play();
      }, 500);
    },

    /**
     * 点击弹唱日期
     */
    async onClickPerformanceDate(performanceDate: string) {
      await querySong({
        performanceDate: performanceDate,
      });
    },

    /**
     * 绑定标签
     */
    async onClickBindTagRelationButton(song: Song) {
      this.selectSongId = song.songId;
      // todo 弹窗出现tag选择栏
      this.showTagList = true;
      const response: QueryTagResponse = await queryAllTag();
      this.tags = [];
      this.selectIds = [];
      this.activeTagIndex = 0;

      _.uniq(
        response.list.map((tag) => {
          return tag.type;
        })
      ).forEach((type) => {
        const item: TreeSelectItem = {
          text: type,
          children: [],
        };
        response.list
          .filter((tag) => {
            return _.isEqual(tag.type, type);
          })
          .forEach((tag) => {
            const child: TreeSelectChild = {
              id: tag.id,
              text: tag.value,
            };
            item.children.push(child);
          });
        this.tags.push(item);
      });
    },

    /**
     * todo
     */
    async chooseTags() {
      this.showTagList = false;

      logger.info("selectSongId", this.selectSongId);
      logger.info("selectIds", this.selectIds);

      await bindTagRelation({
        songId: this.selectSongId,
        tagIdList: this.selectIds,
      });
    },

    /**
     * 添加当前页所有歌曲到播放列表
     */
    addAllToPlayList() {
      this.clear();
      this.songResourceList.forEach((res: QuerySongResponseResource) => {
        this.addSong(res.song);
      });

      const audio = document.getElementById("audio") as HTMLAudioElement;

      audio.setAttribute(
        "src",
        `${process.env.VUE_APP_SONG_SRC}${this.songList[0].path}`
      );
      audio.oncanplaythrough = () => {
        this.updateDuration(audio.duration);
      };

      this.setSong(this.songList[0]);
    },

    /**
     * 更新页码
     */
    async changePage(newPage: number) {
      this.updatePage(newPage);
    },

    /**
     * 显示标签菜单
     */
    showTagMenu(
      event: MouseEvent,
      songResource: QuerySongResponseResource,
      tagValue: string
    ) {
      const RIGHT_CLICK = 2;
      if (event.button == RIGHT_CLICK) {
        const tags: Tag[] = JSON.parse(sessionStorage.getItem("tags"));
        const filteredTags = tags.filter((tag) => {
          return _.isEqual(tag.value, tagValue);
        });
        if (filteredTags.length == 1) {
          this.unbindSongId = songResource.song.songId;
          this.unbindTagId = filteredTags[0].id;
        } else {
          logger.error(`匹配标签时出错`, filteredTags);
        }

        this.tagMenuVisible = true;
      }
    },

    /**
     * 关闭标签菜单
     */
    closeTagMenu() {
      this.unbindSongId = -1;
      this.unbindTagId = -1;
      this.tagMenuVisible = false;
    },

    /**
     * 通过标签id查询歌曲
     */
    async querySongByTagId(queryTagId: number) {
      console.log(
        "🚀 ~ file: Song.vue ~ line 387 ~ querySongByTagId ~ queryTagId",
        queryTagId
      );
    },

    /**
     * 删除标签
     *
     * @param songId 歌曲id
     * @param tagId 标签id
     */
    async deleteTag(songId: number, tagId: number) {
      await unbindTagRelation({
        songId: songId,
        tagId: tagId,
      });
      this.closeTagMenu();
    },

    /**
     * 绑定歌手id
     *
     * @param songId 歌曲id
     * @param singerId 歌手id
     */
    async onRelateSingerId(songId: number, singerId: number) {
      await bindCoverRelation({
        songId: songId,
        singerId: singerId,
      });
      Toast("绑定翻唱成功");
    },

    /**
     * 展示歌曲详细信息
     *
     * @param song 歌曲
     */
    async showDetail(song: Song) {
      this.updateSong = song;
      this.songDetailVisible = true;
    },

    /**
     * 关闭歌曲详细信息
     */
    closeSongDetail() {
      this.songDetailVisible = false;
    },
  },
});
