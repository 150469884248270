
import { updateSong } from "@/api/command.api";
import { Tag, UpdateSongRequest } from "@/types";
import { Toast } from "vant";
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  name: "SongDetail",

  props: ["song"],

  emits: ["onClose"],

  setup() {
    const data = reactive({});

    return {
      ...toRefs(data),
    };
  },

  data() {
    // 更新请求
    const request: UpdateSongRequest = {
      songId: 0,
      fileName: "",
      performanceDate: "",
      songFileContent: "",
    };

    return {
      // 歌曲文件内容
      file: {
        content: "",
        name: "",
        type: "",
        size: "",
      },
      request,
    };
  },

  methods: {
    /**
     * 读取预操作
     */
    beforeRead(file: any): boolean {
      // 检查文件类型
      if (file.type !== "audio/wav" && file.type !== "audio/mpeg") {
        Toast("请上传标准音乐格式文件");
        return false;
      }
      return true;
    },

    /**
     * 读取后操作
     */
    afterRead(event: any): void {
      // 分析并获取文件相关信息
      this.file.content = event.content;
      this.file.name = event.file.name;
      this.file.type = event.file.type;
      // todo 文件大小设定+kb
      this.file.size = (event.file.size / 1024 / 1024).toFixed(2) + "MB";

      this.request.fileName = this.file.name;
      this.request.songFileContent = this.file.content;
    },

    /**
     * 根据标签id获取标签值
     */
    getTagValue(tagIds: number[]): string[] {
      const tags: Tag[] = JSON.parse(sessionStorage.getItem("tags"));

      return tags
        .filter((tag) => {
          return tagIds.includes(tag.id);
        })
        .map((tag) => {
          return tag.value;
        });
    },

    /**
     * 点击导入按钮
     */
    async onClickUpdateSong() {
      this.request.songId = this.song.songId;
      this.request.performanceDate = this.song.performanceDate;
      console.log(this.request);
      // 上传单曲
      await updateSong(this.request);

      this.onClickButton();
    },

    /**
     * 返回按钮
     */
    onClickButton() {
      this.request.songId = 0;
      this.request.songFileContent = "";
      this.request.fileName = "";

      this.file = {
        content: "",
        name: "",
        type: "",
        size: "",
      };
      this.$emit("onClose");
    },
  },
});
