import _ from "lodash";
import { GetterTree } from "vuex";

export const enum CSGetterType {
  // 是否播放中
  isPlaying = "isPlaying",
  // 是否加载中
  isLoading = "isLoading",
  // 总时长
  duration = "duration",
  // 已播放时长
  currentTime = "currentTime",
  // 进度条位置
  position = "position",
  // 歌名
  songName = "songName",
  // 弹唱日期
  performanceDate = "performanceDate",
  // 来源
  src = "src",
}

export const currentSongStageGetters: GetterTree<CurrentSongState, RootState> =
  {
    [CSGetterType.isPlaying]: (state) => state.isPlaying,

    [CSGetterType.isLoading]: (state) => state.isLoading,

    [CSGetterType.duration]: (state) => state.duration.toFixed(3),

    [CSGetterType.currentTime]: (state) => state.currentTime.toFixed(3),

    [CSGetterType.position]: (state) => {
      if (_.isEqual(state.duration, 0.0)) {
        state.position = 0.0;
      } else {
        state.position = state.currentTime / state.duration;
      }
      return _.round(state.position, 3);
    },

    [CSGetterType.songName]: (state) => state.songName,

    [CSGetterType.performanceDate]: (state) => state.performanceDate,

    [CSGetterType.src]: (state) => state.src,
  };
