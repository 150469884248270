
import { deleteSongCollection } from "@/api/command.api";
import { querySong } from "@/api/query.api";
import { ModuleName, store } from "@/store";
import { GCActionType } from "@/store/global-control/actions";
import { GCGetterType } from "@/store/global-control/getters";
import { GCMutationType } from "@/store/global-control/mutations";
import { logger } from "@/util";
import { defineComponent } from "@vue/runtime-core";
import _ from "lodash";
import { reactive, toRefs } from "vue";
import { mapActions, mapGetters } from "vuex";

/**
 * 歌曲集合展示用组件
 */
export default defineComponent({
  name: "SongCollectionComp",

  setup() {
    logger.info("song collection component setup");
    const data = reactive({});

    return {
      ...toRefs(data),
    };
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters(ModuleName.GC, {
      // 总页码
      totalPage: GCGetterType.totalPage,
      // 每页查询结果数目
      pageSize: GCGetterType.pageSize,
      // 展示数据
      songCollectionResourceList: GCGetterType.songCollectionResourceList,
    }),

    // 当前页码
    currentPage: {
      get: () => {
        return store.getters[_.join([ModuleName.GC, GCGetterType.page], "/")];
      },

      set: (value: number) => {
        store.commit(
          _.join([ModuleName.GC, GCMutationType.SET_QUERY_PAGE], "/"),
          value
        );
      },
    },
  },

  methods: {
    ...mapActions(ModuleName.GC, {
      updatePage: GCActionType.UPDATE_PAGE,
    }),

    /**
     * 点击歌曲集合 展示歌曲列表
     */
    async onClickSongCollection(songCollectionId: number) {
      logger.info("on click song colleciton");
      // 查询对应歌曲
      await querySong({ songCollectionId: songCollectionId });
    },

    /**
     * 删除歌曲合集
     */
    async onClickDeleteSongCollection(songCollectionId: number) {
      await deleteSongCollection({ songCollectionId: songCollectionId });
    },

    /**
     * 更新页码
     */
    async changePage(newPage: number) {
      this.updatePage(newPage);
    },
  },
});
