import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "detail" }
const _hoisted_3 = { class: "detail-return" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_uploader = _resolveComponent("van-uploader")!
  const _component_van_form = _resolveComponent("van-form")!
  const _component_van_cell = _resolveComponent("van-cell")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_van_form, { class: "update-song-form" }, {
        default: _withCtx(() => [
          _createVNode(_component_van_uploader, {
            "before-read": _ctx.beforeRead,
            "after-read": _ctx.afterRead,
            "max-count": "1",
            accept: "audio/*"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_van_button, {
                icon: "plus",
                type: "primary",
                text: "选择单曲",
                style: {"margin":"auto"}
              })
            ]),
            _: 1
          }, 8, ["before-read", "after-read"])
        ]),
        _: 1
      }),
      _createVNode(_component_van_button, {
        round: "",
        block: "",
        type: "primary",
        text: "确认更新",
        onClick: _ctx.onClickUpdateSong
      }, null, 8, ["onClick"]),
      _createVNode(_component_van_cell, {
        class: "update-song-info",
        title: _ctx.song.songName,
        value: _ctx.file.name + ' ' + _ctx.file.size
      }, {
        label: _withCtx(() => [
          _createTextVNode(" creator:" + _toDisplayString(_ctx.song.creator), 1),
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" uploader:" + _toDisplayString(_ctx.song.uploader), 1),
          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.song.performanceDate), 1),
          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
        ]),
        _: 1
      }, 8, ["title", "value"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_van_button, {
          class: "detail-return-button",
          type: "default",
          text: "返回",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickButton()))
        })
      ])
    ])
  ]))
}