
import Description from "@/components/Description.vue";
import MusicPlayer from "@/components/MusicPlayer.vue";
import { ModuleName } from "@/store";
import { CSGetterType } from "@/store/current-song/getters";
import { PLGetterType } from "@/store/play-list/getters";
import { defineComponent, reactive } from "@vue/runtime-core";
import _ from "lodash";
import { toRefs } from "vue";
import { mapGetters, useStore } from "vuex";

export default defineComponent({
  name: "Main",

  components: {
    MusicPlayer,
    Description,
  },

  setup() {
    const store = useStore();
    const data = reactive({});

    return {
      store,
      ...toRefs(data),
    };
  },

  data() {
    return {
      // 是否选中按钮
      checked: _.stubFalse(),
      // 鼠标悬浮播放项目所在序号
      hoverIndex: -1,
      // 是否展示说明信息
      showDescription: _.stubFalse(),
      // 检索文本
      searchText: _.stubString(),
    };
  },

  computed: {
    ...mapGetters(ModuleName.CS, {
      isPlaying: CSGetterType.isPlaying,
    }),

    ...mapGetters(ModuleName.PL, {
      playList: PLGetterType.playList,
      playIndex: PLGetterType.playIndex,
    }),
  },

  methods: {
    /**
     * 播放控制
     */
    showController(index: number): void {
      this.hoverIndex = index;
    },

    /**
     * 获取播放器引用
     */
    getMusicPlayer() {
      return this.$refs.musicPlayer as any;
    },

    /**
     * 切换播放状态
     */
    async togglePlaying(): Promise<void> {
      await this.getMusicPlayer().togglePlaying();
    },

    /**
     * 切换播放项目
     *
     * @param songId 待播放项目歌曲id
     */
    changePlayItem(songId: number): void {
      this.getMusicPlayer().playSong(songId);
      this.getMusicPlayer().setPlayItem(this.playList[this.playIndex]);
      this.getMusicPlayer().playItem();
    },

    /**
     * 设置随机歌单
     */
    async setRandomPlayList(): Promise<void> {
      await this.getMusicPlayer().setRandomPlayList();
    },

    /**
     * 设置指定条件歌单
     *
     * @param searchText 检索文本
     */
    async setSpecifiedPlayList(searchText: string): Promise<void> {
      await this.getMusicPlayer().setSpecifiedPlayList(searchText);
    },
  },
});
