import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "search-bar-container" }
const _hoisted_3 = { class: "manage-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_dropdown_item = _resolveComponent("van-dropdown-item")!
  const _component_van_dropdown_menu = _resolveComponent("van-dropdown-menu")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_col = _resolveComponent("van-col")!
  const _component_van_row = _resolveComponent("van-row")!
  const _component_song_collection_comp = _resolveComponent("song-collection-comp")!
  const _component_van_tab = _resolveComponent("van-tab")!
  const _component_song_comp = _resolveComponent("song-comp")!
  const _component_van_tabs = _resolveComponent("van-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_van_field, {
        class: "search-bar",
        center: "",
        modelValue: _ctx.queryContent,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.queryContent) = $event)),
        placeholder: "请输入你要查询的内容"
      }, {
        label: _withCtx(() => [
          _createVNode(_component_van_dropdown_menu, null, {
            default: _withCtx(() => [
              _createVNode(_component_van_dropdown_item, {
                modelValue: _ctx.currentQueryType,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentQueryType) = $event)),
                options: _ctx.queryOptions
              }, null, 8, ["modelValue", "options"])
            ]),
            _: 1
          })
        ]),
        button: _withCtx(() => [
          _createVNode(_component_van_button, {
            icon: "search",
            size: "normal",
            type: "primary",
            onClick: _ctx.onClickQueryButton
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" 查询歌集 ")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createVNode(_component_van_button, { onClick: _ctx.onClickQueryAllSongCollection }, {
      default: _withCtx(() => _cache[4] || (_cache[4] = [
        _createTextVNode(" 查询所有歌曲集合 ")
      ])),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_van_button, { onClick: _ctx.onClickQueryAllSong }, {
      default: _withCtx(() => _cache[5] || (_cache[5] = [
        _createTextVNode("查询所有歌曲")
      ])),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_van_col, {
        class: "show-search-result-container",
        span: "24"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_van_tabs, {
            active: _ctx.activeName,
            "onUpdate:active": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeName) = $event)),
            "line-width": "20%"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_van_tab, {
                title: "歌曲合集",
                name: "song_collection"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_van_row, { class: "filter-table" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_van_col, { span: "6" }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("歌名")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_van_col, { span: "4" }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode("原唱")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_van_col, { span: "14" }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode("Tags")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_song_collection_comp)
                ]),
                _: 1
              }),
              _createVNode(_component_van_tab, {
                title: "歌曲",
                name: "song"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_van_row, { class: "filter-table" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_van_col, { span: "5" }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode("日期")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_van_col, { span: "7" }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("歌名")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_van_col, { span: "2" }),
                      _createVNode(_component_van_col, { span: "3" }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode("翻唱")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_van_col, { span: "5" }, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createTextVNode("Tags")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_van_col, { span: "2" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_song_comp)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["active"])
        ]),
        _: 1
      })
    ])
  ]))
}