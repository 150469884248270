import { PlayMode } from "@/common/enums";
import { MutationTree } from "vuex";

export const enum PLMutationType {
  // 新增一首歌曲
  ADD_SONG = "ADD_SONG",
  // 清空列表
  CLEAR = "CLEAR",
  // 获取歌曲详细信息
  GET_SONG_DETAIL = "GET_SONG_DETAIL",
  // 播放下一首
  PLAY_NEXT = "PLAY_NEXT",
  // 播放上一首
  PLAY_PREV = "PLAY_PREV",
  // 播放指定项目
  PLAY_SONG = "PLAY_SONG",
}

export const playListStateMutations: MutationTree<PlayListState> = {
  // 新增一首歌曲
  [PLMutationType.ADD_SONG](state, item: PlayListItem) {
    if (state.playList.includes(item)) {
      return;
    } else {
      // todo 新增位置 默认在播放列表末尾
      state.playList.push(item);
      // todo 持久化
    }
  },

  // 清空播放列表
  [PLMutationType.CLEAR](state) {
    state.playIndex = 0;
    state.playList = [];
  },

  // todo 获取歌曲详细信息
  // 播放下一首
  [PLMutationType.PLAY_NEXT](state) {
    switch (state.playMode) {
      case PlayMode.RANDOM:
        // TODO
        break;
      case PlayMode.IN_ORDER:
        ++state.playIndex;
        break;
      case PlayMode.LIST_LOOP:
        state.playIndex = (state.playIndex + 1) % state.playList.length;
        break;
      case PlayMode.SONG_LOOP:
        break;

      default:
        break;
    }
  },

  // 播放上一首
  [PLMutationType.PLAY_PREV](state) {
    switch (state.playMode) {
      case PlayMode.RANDOM:
        // TODO
        break;
      case PlayMode.IN_ORDER:
        --state.playIndex;
        break;
      case PlayMode.LIST_LOOP:
        state.playIndex =
          state.playIndex == 1 ? state.playList.length : state.playIndex - 1;
        break;
      case PlayMode.SONG_LOOP:
        break;

      default:
        break;
    }
  },

  // 播放指定项目
  [PLMutationType.PLAY_SONG](state, songId: number) {
    state.playList.forEach((item, index) => {
      if (songId === item.song.songId) {
        state.playIndex = index;
      }
    });
  },
};
