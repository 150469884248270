import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "top-container" }
const _hoisted_3 = { class: "description" }
const _hoisted_4 = { class: "center-container" }
const _hoisted_5 = { class: "song-info-container" }
const _hoisted_6 = { class: "operate-bar" }
const _hoisted_7 = { class: "random-condition" }
const _hoisted_8 = { class: "search-condition" }
const _hoisted_9 = { class: "play-list" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_description = _resolveComponent("description")!
  const _component_van_overlay = _resolveComponent("van-overlay")!
  const _component_van_icon = _resolveComponent("van-icon")!
  const _component_van_col = _resolveComponent("van-col")!
  const _component_van_row = _resolveComponent("van-row")!
  const _component_music_player = _resolveComponent("music-player")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_overlay, {
      "lock-scroll": false,
      show: _ctx.showDescription
    }, {
      default: _withCtx(() => [
        _createVNode(_component_description, {
          onOnClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDescription = false))
        })
      ]),
      _: 1
    }, 8, ["show"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_van_icon, { name: "info-o" }),
        _createElementVNode("span", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDescription = true))
        }, " 关于本站（首次访问可能较慢，请耐心等待） ")
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, [
            _cache[7] || (_cache[7] = _createElementVNode("span", null, "随便听听", -1)),
            _createVNode(_component_van_icon, {
              size: "20px",
              name: "replay",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setRandomPlayList()))
            })
          ]),
          _createElementVNode("span", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "search-condition",
              placeholder: "支持歌名模糊查询 例:夜明けと蛍",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchText) = $event)),
              onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setSpecifiedPlayList(_ctx.searchText)))
            }, null, 544), [
              [_vModelText, _ctx.searchText]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playList, (playItem, index) => {
            return (_openBlock(), _createBlock(_component_van_row, {
              class: _normalizeClass(["play-item", index === _ctx.playIndex ? 'item-playing' : 'item-not-playing']),
              key: index,
              onMouseenter: ($event: any) => (_ctx.showController(index)),
              onMouseleave: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showController(-1)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_van_col, {
                  span: "2",
                  class: "item-index"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(index + 1), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_van_col, {
                  span: "8",
                  class: "item-date"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(playItem.song.performanceDate), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_van_col, {
                  span: "3",
                  class: "item-controller"
                }, {
                  default: _withCtx(() => [
                    (index === _ctx.playIndex)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_component_van_icon, {
                            name: _ctx.isPlaying ? 'pause' : 'play',
                            size: "18",
                            color: "white",
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.togglePlaying()))
                          }, null, 8, ["name"])
                        ]))
                      : (index === _ctx.hoverIndex)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createVNode(_component_van_icon, {
                              name: "play",
                              size: "18",
                              color: "white",
                              onClick: ($event: any) => (_ctx.changePlayItem(playItem.song.songId))
                            }, null, 8, ["onClick"])
                          ]))
                        : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_van_col, {
                  span: "11",
                  class: "item-name"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(playItem.songCollection.songTitle), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["class", "onMouseenter"]))
          }), 128))
        ])
      ])
    ]),
    _createVNode(_component_music_player, { ref: "musicPlayer" }, null, 512)
  ]))
}