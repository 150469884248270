import { PlayMode } from "@/common/enums";
import { Module } from "vuex";
import { playListStageGetters } from "./getters";
import { playListStateMutations } from "./mutations";

/**
 * 初期化播放列表状态
 */
const initPlayListState: PlayListState = {
  playList: [],
  playIndex: 0,
  playMode: PlayMode.IN_ORDER,
};

export const pl: Module<PlayListState, RootState> = {
  namespaced: true,

  state: initPlayListState,

  getters: playListStageGetters,

  mutations: playListStateMutations,

  actions: {},
};
