import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "operation-container" }
const _hoisted_2 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_form = _resolveComponent("van-form")!
  const _component_van_tab = _resolveComponent("van-tab")!
  const _component_van_switch = _resolveComponent("van-switch")!
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_calendar = _resolveComponent("van-calendar")!
  const _component_van_uploader = _resolveComponent("van-uploader")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_tabs = _resolveComponent("van-tabs")!
  const _component_van_col = _resolveComponent("van-col")!
  const _component_van_tree_select = _resolveComponent("van-tree-select")!
  const _component_van_overlay = _resolveComponent("van-overlay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_van_col, { span: "15" }, {
        default: _withCtx(() => [
          _createVNode(_component_van_tabs, {
            active: _ctx.activeTabIndex,
            "onUpdate:active": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.activeTabIndex) = $event)),
            type: "line",
            ellipsis: "false",
            background: "var(--color-nano-black-revert)",
            color: "var(--color-nano-black)",
            "title-active-color": "var(--color-nano-black)",
            "title-inactive-color": "#fff"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_van_tab, { title: "新增歌手 / 歌曲集合 / 标签" }, {
                default: _withCtx(() => [
                  _createVNode(_component_van_form, {
                    class: "add-singer",
                    onSubmit: _ctx.onClickAddSinger
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_van_field, {
                        modelValue: _ctx.singer.singerName,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.singer.singerName) = $event)),
                        label: "歌手姓名",
                        placeholder: "要新增的歌手姓名",
                        required: "",
                        rules: [{ required: true, message: '请填写歌手姓名' }]
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_van_button, {
                        round: "",
                        block: "",
                        text: "新增歌手（精确）",
                        type: "primary",
                        "native-type": "submit",
                        style: {"margin":"16px auto"}
                      })
                    ]),
                    _: 1
                  }, 8, ["onSubmit"]),
                  _createVNode(_component_van_form, {
                    class: "add-song-collection",
                    onSubmit: _ctx.onClickAddCollection
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_van_field, {
                        modelValue: _ctx.songCollection.songTitle,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.songCollection.songTitle) = $event)),
                        label: "歌曲集合",
                        placeholder: "新增歌曲集合名",
                        required: "",
                        rules: [{ required: true, message: '请填写歌名' }]
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_van_field, {
                        modelValue: _ctx.songCollection.chineseTitle,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.songCollection.chineseTitle) = $event)),
                        label: "中文名",
                        placeholder: "新增歌曲集合中文名"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_van_field, {
                        modelValue: _ctx.songCollection.englishTitle,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.songCollection.englishTitle) = $event)),
                        label: "英文名",
                        placeholder: "新增歌曲集合英文名"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_van_button, {
                        round: "",
                        block: "",
                        text: "新增歌曲集合（精确）",
                        type: "primary",
                        "native-type": "submit",
                        style: {"margin":"16px auto"}
                      })
                    ]),
                    _: 1
                  }, 8, ["onSubmit"]),
                  _createVNode(_component_van_form, { onSubmit: _ctx.onClickAddTag }, {
                    default: _withCtx(() => [
                      _createVNode(_component_van_field, {
                        modelValue: _ctx.tag.type,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tag.type) = $event)),
                        label: "标签类型",
                        placeholder: "例:乐器",
                        required: "",
                        rules: [{ required: true, message: '请填写标签类型' }]
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_van_field, {
                        modelValue: _ctx.tag.value,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.tag.value) = $event)),
                        label: "标签值",
                        placeholder: "例:钢琴",
                        required: "",
                        rules: [{ required: true, message: '请填写标签值' }]
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_van_button, {
                        round: "",
                        block: "",
                        text: "新增标签（精确）",
                        type: "primary",
                        "native-type": "submit",
                        style: {"margin":"16px auto"}
                      })
                    ]),
                    _: 1
                  }, 8, ["onSubmit"])
                ]),
                _: 1
              }),
              _createVNode(_component_van_tab, { title: "导入歌曲" }, {
                default: _withCtx(() => [
                  _createVNode(_component_van_form, {
                    class: "import-song",
                    onSubmit: _ctx.onClickUploadSong
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_van_field, {
                        modelValue: _ctx.songCollection.songTitle,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.songCollection.songTitle) = $event)),
                        label: "歌曲集合",
                        placeholder: "要上传到的歌曲集合",
                        required: "",
                        rules: [{ required: true, message: '歌曲集合为必填项' }]
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_van_field, {
                        modelValue: _ctx.song.uploader,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.song.uploader) = $event)),
                        label: "歌曲上传者",
                        required: "",
                        rules: [{ required: true, message: '请输入上传者' }]
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_van_field, {
                        modelValue: _ctx.song.creator,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.song.creator) = $event)),
                        label: "歌曲创建者",
                        required: "",
                        rules: [{ required: true, message: '请输入创建者' }]
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_van_field, {
                        modelValue: _ctx.song.playOrder,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.song.playOrder) = $event)),
                        label: "歌曲弹唱顺序"
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_van_cell, {
                        title: _ctx.song.membersOnly ? '会限' : '非会限'
                      }, {
                        "right-icon": _withCtx(() => [
                          _createVNode(_component_van_switch, {
                            modelValue: _ctx.song.membersOnly,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.song.membersOnly) = $event)),
                            size: "24"
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }, 8, ["title"]),
                      _createVNode(_component_van_cell, {
                        title: "点击选择弹唱日期",
                        "is-link": "",
                        value: _ctx.song.performanceDate,
                        onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showCalender = true))
                      }, null, 8, ["value"]),
                      _createVNode(_component_van_calendar, {
                        show: _ctx.showCalender,
                        "onUpdate:show": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.showCalender) = $event)),
                        "min-date": new Date(2021, 4, 1),
                        "max-date": new Date(2023, 4, 1),
                        "show-confirm": false,
                        onConfirm: _ctx.onConfirmPerformanceDate
                      }, null, 8, ["show", "min-date", "max-date", "onConfirm"]),
                      _createVNode(_component_van_uploader, {
                        "before-read": _ctx.beforeRead,
                        "after-read": _ctx.afterRead,
                        "max-count": "1",
                        accept: "audio/*"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_van_button, {
                            icon: "plus",
                            type: "primary",
                            text: "选择单曲",
                            style: {"margin":"16px auto"}
                          })
                        ]),
                        _: 1
                      }, 8, ["before-read", "after-read"]),
                      _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1)),
                      _createTextVNode(_toDisplayString(_ctx.file.name), 1),
                      _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1)),
                      _createTextVNode(_toDisplayString(_ctx.file.size) + " ", 1),
                      _createVNode(_component_van_button, {
                        round: "",
                        block: "",
                        type: "primary",
                        text: "新增导入（精确）",
                        "native-type": "submit"
                      })
                    ]),
                    _: 1
                  }, 8, ["onSubmit"]),
                  _createVNode(_component_van_cell_group, { title: "导入结果" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.importSongList, (song, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          _createTextVNode(_toDisplayString(song.playOrder) + " ", 1),
                          _createVNode(_component_van_cell, {
                            title: song.songTitle,
                            value: song.fileName + ' ' + song.size
                          }, {
                            label: _withCtx(() => [
                              _createTextVNode(" creator:" + _toDisplayString(song.creator), 1),
                              _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1)),
                              _createTextVNode(" " + _toDisplayString(song.performanceDate), 1),
                              _cache[23] || (_cache[23] = _createElementVNode("br", null, null, -1)),
                              _createTextVNode(" " + _toDisplayString(_ctx.getTagValue(song.tagIds)) + " ", 1),
                              _createVNode(_component_van_button, {
                                type: "primary",
                                size: "mini",
                                icon: "plus",
                                onClick: ($event: any) => (_ctx.onClickBindTagRelationButton(index))
                              }, null, 8, ["onClick"])
                            ]),
                            _: 2
                          }, 1032, ["title", "value"])
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_van_button, {
                    round: "",
                    block: "",
                    type: "primary",
                    text: "确认导入",
                    onClick: _ctx.onClickImportSong
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_van_tab, { title: "关联" }, {
                default: _withCtx(() => [
                  _createVNode(_component_van_form, {
                    class: "relate-original-singer",
                    onSubmit: _ctx.onClickRelateOriginalRelation
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_van_field, {
                        modelValue: _ctx.songCollection.songTitle,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.songCollection.songTitle) = $event)),
                        label: "歌曲集合",
                        placeholder: "要设定的歌曲集合名",
                        required: "",
                        rules: [{ required: true, message: '请设定歌曲' }]
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_van_field, {
                        modelValue: _ctx.singer.singerName,
                        "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.singer.singerName) = $event)),
                        label: "歌手姓名",
                        placeholder: "要设定的歌手姓名",
                        required: "",
                        rules: [{ required: true, message: '请设定歌手' }]
                      }, null, 8, ["modelValue"]),
                      _createVNode(_component_van_button, {
                        round: "",
                        block: "",
                        type: "primary",
                        "native-type": "submit",
                        text: "设定歌曲集合+原唱（精确）",
                        style: {"margin":"16px auto"}
                      })
                    ]),
                    _: 1
                  }, 8, ["onSubmit"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["active"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_van_overlay, { show: _ctx.showTagList }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_van_tree_select, {
            class: "block",
            "active-id": _ctx.selectIds,
            "onUpdate:activeId": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.selectIds) = $event)),
            "main-active-index": _ctx.activeTagIndex,
            "onUpdate:mainActiveIndex": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.activeTagIndex) = $event)),
            height: "80%",
            items: _ctx.tags
          }, null, 8, ["active-id", "main-active-index", "items"]),
          _createVNode(_component_van_button, {
            onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.chooseTags())),
            text: "确认"
          }),
          _createVNode(_component_van_button, {
            onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.setDefaultTags())),
            text: "设置默认标签"
          })
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}