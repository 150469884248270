/** 标签页名称 */
export const TabName = {
  songCollection: "song_colleciton",
  song: "song",
};

/** 后端请求路径 */
export const Url = {
  v1: {
    command: {
      relation: {
        coverRelation: "/cover-relation",
        originalRelation: "/original-relation",
        tagRelation: "/tag-relation",
      },
      entity: {
        singer: "/singer",
        songCollection: "/song-collection",
        song: "/song",
        tag: "/tag",
      },
    },

    query: {
      all: {
        song: "/query-song/all",
        songCollection: "/query-song-collection/all",
        tag: "/query-tag/all",
      },
      random: {
        song: "/query-song/random",
      },
      specified: {
        song: "/query-song",
        songCollection: "/query-song-collection",
      },
    },
  },

  v2: {
    fetchData: "/v2/data",
  },
};
