import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_col = _resolveComponent("van-col")!
  const _component_van_tag = _resolveComponent("van-tag")!
  const _component_van_button = _resolveComponent("van-button")!
  const _component_van_tree_select = _resolveComponent("van-tree-select")!
  const _component_van_overlay = _resolveComponent("van-overlay")!
  const _component_van_row = _resolveComponent("van-row")!
  const _component_van_pagination = _resolveComponent("van-pagination")!
  const _component_song_detail = _resolveComponent("song-detail")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.songResourceList, (songResource, index) => {
      return (_openBlock(), _createBlock(_component_van_row, {
        class: "song-item",
        key: index
      }, {
        default: _withCtx(() => [
          _createVNode(_component_van_col, { span: "1" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString((_ctx.currentPage - 1) * _ctx.pageSize + index + 1), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_van_col, {
            span: "3",
            onClick: ($event: any) => (_ctx.onClickPerformanceDate(songResource.song.performanceDate))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(songResource.song.performanceDate), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]),
          _createVNode(_component_van_col, {
            span: "8",
            style: {"text-align":"left"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(songResource.song.songName), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_van_col, {
            class: "singer-list",
            span: "3"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(songResource.singers, (singer, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "singer-item",
                  key: index
                }, [
                  _createVNode(_component_van_tag, {
                    type: "primary",
                    size: "large",
                    plain: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(singer.singerName), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_van_col, {
            class: "tag-list",
            span: "6"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(songResource.tagValues, (tagValue, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "tag-item",
                  key: index
                }, [
                  _createVNode(_component_van_tag, {
                    type: "primary",
                    size: "large",
                    plain: "",
                    onContextmenu: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
                    onMousedown: ($event: any) => (_ctx.showTagMenu($event, songResource, tagValue))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(tagValue), 1)
                    ]),
                    _: 2
                  }, 1032, ["onMousedown"])
                ]))
              }), 128)),
              _createVNode(_component_van_button, {
                type: "primary",
                size: "mini",
                icon: "plus",
                onClick: ($event: any) => (_ctx.onClickBindTagRelationButton(songResource.song))
              }, null, 8, ["onClick"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_van_col, { span: "1" }, {
            default: _withCtx(() => [
              _createVNode(_component_van_button, {
                size: "mini",
                onClick: ($event: any) => (_ctx.showDetail(songResource.song))
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode(" 详细 ")
                ])),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_van_overlay, { show: _ctx.showTagList }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_van_tree_select, {
                  class: "block",
                  "active-id": _ctx.selectIds,
                  "onUpdate:activeId": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectIds) = $event)),
                  "main-active-index": _ctx.activeTagIndex,
                  "onUpdate:mainActiveIndex": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeTagIndex) = $event)),
                  height: "40%",
                  items: _ctx.tags
                }, null, 8, ["active-id", "main-active-index", "items"]),
                _createVNode(_component_van_button, {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.chooseTags())),
                  text: "确认"
                })
              ])
            ]),
            _: 1
          }, 8, ["show"])
        ]),
        _: 2
      }, 1024))
    }), 128)),
    (_ctx.songResourceList.length)
      ? (_openBlock(), _createBlock(_component_van_pagination, {
          key: 0,
          class: "pagination",
          modelValue: _ctx.currentPage,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentPage) = $event)),
          "page-count": _ctx.totalPage,
          "show-page-size": 20,
          "force-ellipses": "",
          onChange: _ctx.changePage
        }, null, 8, ["modelValue", "page-count", "onChange"]))
      : _createCommentVNode("", true),
    _createVNode(_component_van_overlay, { show: _ctx.tagMenuVisible }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_van_button, {
            type: "warning",
            size: "small",
            text: "查找",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.querySongByTagId(_ctx.queryTagId)))
          }),
          _createVNode(_component_van_button, {
            type: "warning",
            size: "small",
            text: "复制id值",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.copyTagId()))
          }),
          _createVNode(_component_van_button, {
            type: "primary",
            size: "small",
            text: "返回",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.closeTagMenu()))
          })
        ])
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(_component_van_overlay, { show: _ctx.songDetailVisible }, {
      default: _withCtx(() => [
        _createVNode(_component_song_detail, {
          song: _ctx.updateSong,
          onOnClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.closeSongDetail()))
        }, null, 8, ["song"])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}