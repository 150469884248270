import { Url } from "@/common/constants";
import { commonRequest } from "@/util";
import { Toast } from "vant";

/**
 * 绑定歌曲与歌手
 *
 * @param request 绑定翻唱请求
 */
export const bindCoverRelation = async (
  request: BindCoverRelationRequest
): Promise<void> => {
  await commonRequest.post(Url.v1.command.relation.coverRelation, request);
};

/**
 * 关联歌曲集合与歌手
 *
 * @param request 关联原唱请求
 */
export const relateOriginalRelation = async (
  request: RelateOriginalRelationRequest
): Promise<void> => {
  await commonRequest
    .post(Url.v1.command.relation.originalRelation, request)
    .then(() => Toast("关联原唱成功"));
};

/**
 * 新增歌手
 *
 * @param request 新增歌手请求
 */
export const addSinger = async (request: AddSingerRequest): Promise<void> => {
  await commonRequest
    .post(Url.v1.command.entity.singer, request)
    .then(() => Toast("新增歌手成功"));
};

/**
 * 导入歌曲
 * 默认翻唱歌手为 椎名なのは
 *
 * @param request 导入歌曲请求
 */
export const importSong = async (request: ImportSongRequest): Promise<void> => {
  await commonRequest
    .post(Url.v1.command.entity.song, request)
    .then(() => Toast("导入歌曲成功"));
};

/**
 * 更新歌曲
 *
 * @param request 更新歌曲请求
 */
export const updateSong = async (request: UpdateSongRequest): Promise<void> => {
  await commonRequest.put(Url.v1.command.entity.song, request);
};

/**
 * 删除歌曲
 *
 * @param request 删除歌曲请求
 */
export const deleteSong = async (request: DeleteSongRequest): Promise<void> => {
  await commonRequest
    .del(Url.v1.command.entity.song, request)
    .then(() => Toast("删除歌曲成功"));
};

/**
 * 新增歌曲集合
 *
 * @param request 新增歌曲集合请求
 */
export const addSongCollection = async (
  request: AddSongCollectionRequest
): Promise<void> => {
  await commonRequest
    .post(Url.v1.command.entity.songCollection, request)
    .then(() => Toast("新增歌曲集合成功"));
};

/**
 * 删除歌曲集合
 *
 * @param request 删除歌曲集合请求
 */
export const deleteSongCollection = async (
  request: DeleteSongCollctionRequest
): Promise<void> => {
  await commonRequest
    .del(Url.v1.command.entity.songCollection, request)
    .then(() => Toast("删除歌曲集合成功"));
};

/**
 * 新增标签
 *
 * @param request 新增标签请求
 */
export const addTag = async (request: AddTagRequest): Promise<void> => {
  await commonRequest
    .post(Url.v1.command.entity.tag, request)
    .then(() => Toast("新增标签成功"));
};

/**
 * 绑定标签
 *
 * @param request 绑定标签请求
 */
export const bindTagRelation = async (
  request: BindTagRelationRequest
): Promise<void> => {
  await commonRequest
    .post(Url.v1.command.relation.tagRelation, request)
    .then(() => Toast("标签绑定成功"));
};

/**
 * 解绑标签
 *
 * @param request 解绑标签请求
 */
export const unbindTagRelation = async (
  request: UnbindTagRelationRequest
): Promise<void> => {
  await commonRequest
    .del(Url.v1.command.relation.tagRelation, request)
    .then(() => Toast("标签解绑成功"));
};
